import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/layout";
import SEO from "../components/seo";

class ClassTemplate extends React.Component {
  render() {
    const pageData = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout
        location={this.props.location}
        showDescription={false}
        showContact={false}
        title={siteTitle}
        render={() => {
          return (
            <>
              <SEO
                title={pageData.frontmatter.title}
                pathname={this.props.location.pathname}
                // image={post.frontmatter.thumbnail.image}
                // description={post.frontmatter.description || post.excerpt}
              />
              <h1>{pageData.frontmatter.title}</h1>
              <div className="richText marginBottom-15">
                <MDXRenderer>{pageData.body}</MDXRenderer>
              </div>
            </>
          );
        }}
      />
    );
  }
}

export default ClassTemplate;

export const pageQuery = graphql`
  query ClassBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`;
